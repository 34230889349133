@import "/node_modules/flag-icons/css/flag-icons.min.css";

nav {
  right: 0;
  position: fixed;
  z-index: 300;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  height: 50px;

  ul {
    padding: 0;
    display: flex;
    justify-content: flex-end;
    height: 100%;
    margin: 0;

    & > li {
      list-style-type: none;
      display: inline-flex;
      padding: 0 20px;
      height: 100%;
      align-items: center;

      &.language-dropdown {
        display: flex;
      }

      &:hover {
        background-color: rgb(233, 233, 233);
      }

      div.dropdown {
        align-self: stretch;
        padding-top: 7px;
      }

      & > span.language-label {
        align-self: center;
        padding-right: 10px;
      }

      button.dropdown-button {
        display: flex;
        padding: 0 8px;
        border-radius: 4px;
        border: 0;
        border-bottom: 1px solid rgba(77, 77, 77, 0.6);
        background-color: inherit;
        cursor: pointer;

        &:hover {
          background-color: rgba(215, 215, 215, 0.8);
        }
      }

      button.dropdown-list {
        display: flex;
        flex-direction: column;
      }

      button.dropdown-item {
        display: flex;
        padding: 0 8px;
        border: 1px solid gray;
        background-color: rgba(241, 241, 241, 0.9);
        cursor: pointer;

        &:hover {
          background-color: rgba(215, 215, 215, 0.8);
        }
      }

      div.translation-flag, div.caret {
        display: flex;
        align-self: center;
      }

      div.caret {
        padding-left: 10px;
      }

      div.translation-flag > span {
        height: 34px;
        width: 28px;
      }
    }

    @media (max-width: 560px) {
      flex-direction: column;
      padding: 15px 0;
      justify-content: center;
      width: 100%;
      display: flex;
      align-items: center;

      & > li:not(.language-dropdown) {
        flex-direction: column;
        padding: 5px 0;
        width: 100%;
      }

      & > li.language-dropdown > span.language-label {
        align-self: flex-start;
        padding-top: 12px;
      }
    }
  }

  @media (max-width: 560px) {
    height: unset;
    display: flex;
    position: unset;
  }
}
