.page-content {
  position: relative;
  padding-top: 50px;

  @media (max-width: 560px) {
    padding-top: 0;
  }
}

section.footer {
  & > article {
    display: flex;
    align-items: center;
    background-color: #000000;
    padding: 40px 54px;

    & > div.content {
      display: flex;
      flex-direction: column;
      color: white;
      padding-top: 3px;

      span.title {
        font-size: 22px;
        font-weight: 700;
        text-transform: uppercase;
      }

      span.headline {
        padding: 25px 0;
        font-size: 18px;
        font-weight: 600;
      }

      span.body {
        font-size: 16px;
        padding: 3px 0;
      }

    }
  }
}