@mixin text-image-content($textFlexOrder: 1, $imageFlexOrder: 2, $textAlign: flex-start) {
  article {
    padding: 36px 24px;
    display: flex;
    flex-direction: row;

    & > div.content {
      order: $textFlexOrder;
      width: 50%;
      display: flex;
      flex-direction: column;
      padding: 40px 30px;
      justify-content: center;
      align-items: $textAlign;

      & > div.body-content {
        display: flex;
        flex-direction: column;

        & > span.headline {
          font-size: 30px;
          padding: 20px 0;
          font-weight: 700;
        }

        & > span.body {
          font-size: 18px;
          padding: 10px 0;
          max-width: 555px;
          line-height: 33px;
        }

        & > div.button-content {
          padding-top: 20px;

          & > button {
            padding: 0;
            border: 2px solid black;
            font-size: 18px;
            background-color: inherit;

            div {
              padding: 16px 24px;
              text-transform: uppercase;
            }
          }
        }
      }


      @media (max-width: 1200px) {
        width: unset;
        align-items: center;
        order: 1;
      }
    }

    & > div.image-content {
      order: $imageFlexOrder;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;

      & > div.image-container {
        img {
          object-fit: contain;
          max-width: 750px;
          width: 100%;
          height: 100%;
        }
      }

      @media (max-width: 1200px) {
        width: unset;
        max-width: 650px;
        order: 2;
      }
    }

    @media (max-width: 1200px) {
      flex-direction: column;
    }
  }
}

section.text-image-order {
  display: flex;
  background-color: rgb(233, 233, 233);
  justify-content: center;

  @include text-image-content(1, 2);
}

section.image-text-order {
  display: flex;
  background-color: rgb(255, 255, 255);
  justify-content: center;

  @include text-image-content(2, 1, flex-end);
}