section.introduction {
  position: relative;
  width: 100%;
  height: calc(450px - 50px);

  .cover-image::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: linear-gradient(to bottom,rgba(0,0,0,.3) 0,rgba(0,0,0,.5) 100%);
    background-repeat: repeat-x;
    opacity: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
  }

  .cover-image {
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: 1;
    --circle_w: 2px;
    --circle_color: black;
    --bg_color: transparent;
    --m_bet_circle: 2px;
    --A: calc(var(--circle_w) + var(--m_bet_circle));
    --B: calc(var(--circle_w) / 2);
    background: 0 0/var(--A) var(--A) radial-gradient(circle at center,
            var(--circle_color) var(--B),
            var(--bg_color) var(--B)),
    center/cover url(../../assets/images/LAGOS_cover.jpg) no-repeat;

    & > .flex-container {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: flex-end;

      & > .content {
        max-width: 950px;
        width: 100%;
        padding: 0 24px;
        position: relative;
        display: flex;
        flex-direction: column;
        z-index: 3;

        & > .headline {
          padding-bottom: 30px;
          font-size: 80px;
          font-weight: 700;
          color: white;

          @media (max-width: 1200px) {
            font-size: 60px;
          }

          @media (max-width: 700px) {
            font-size: 40px;
          }
        }

        & > .body {
          display: flex;
          justify-content: center;
          font-size: 25px;
          color: rgb(222, 222, 222);

          & > span {
            padding-top: 40px;
            padding-left: 20px;
            padding-right: 20px;
            border-top: 2px solid rgb(222, 222, 222);
            font-weight: 400;
          }
        }
      }
    }
  }
}

section.page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 24px;

  & > div.text-content {
    max-width: 950px;
    width: 100%;

    & > span.headline {
      font-size: 40px;
      font-weight: 500;

      @media (max-width: 1200px) {
        font-size: 30px;
      }

      @media (max-width: 700px) {
        font-size: 20px;
      }
    }

    & > div.body {
      padding: 12px 0;

      & > div.paragraph {
        padding: 8px 0;
        font-size: 18px;

        @media (max-width: 1200px) {
          font-size: 18px;
        }

        @media (max-width: 700px) {
          font-size: 16px;
        }
      }
      & > div.paragraph:nth-child(10) {
        padding: 32px 0;
        font-size: 18px;
        font-weight: 500;

        @media (max-width: 1200px) {
          font-size: 16px;
        }

        @media (max-width: 700px) {
          font-size: 16px;
        }
      }
    }
  }

  div.calendar-content {
    max-width: 950px;
    width: 100%;
    padding: 48px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > span {
      padding: 32px 0;
      font-size: 16px;
      font-weight: 500;

      @media (max-width: 1200px) {
        font-size: 14px;
      }

      @media (max-width: 700px) {
        font-size: 14px;
      }
    }
  }
}
