section.first-view {
  position: relative;
  width: 100%;
  height: calc(100vh - 50px);

  //.cover-image::before {
  //  content: '';
  //  width: 100%;
  //  height: 100%;
  //  position: absolute;
  //  background-image: linear-gradient(to bottom,rgba(0,0,0,.3) 0,rgba(0,0,0,.5) 100%);
  //  background-repeat: repeat-x;
  //  opacity: 1;
  //  top: 0;
  //  right: 0;
  //  bottom: 0;
  //  left: 0;
  //  z-index: 2;
  //}

  .cover-image {
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: url(../../assets/images/LAGOS_cover.jpg) no-repeat;
    //--circle_w: 2px;
    //--circle_color: black;
    //--bg_color: transparent;
    //--m_bet_circle: 2px;
    //--A: calc(var(--circle_w) + var(--m_bet_circle));
    //--B: calc(var(--circle_w) / 2);
    //background: 0 0/var(--A) var(--A) radial-gradient(circle at center,
    //        var(--circle_color) var(--B),
    //        var(--bg_color) var(--B)),
    //center/cover url(../../assets/images/LAGOS_cover.jpg) no-repeat;

    & > .flex-container {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;

      & > .content {
        padding: 0 24px;
        position: relative;
        display: flex;
        flex-direction: column;
        z-index: 3;

        & > .headline {
          padding-bottom: 30px;
          font-size: 80px;
          font-weight: 700;
          color: white;
          text-align: center;

          @media (max-width: 1200px) {
            font-size: 60px;
          }

          @media (max-width: 700px) {
            font-size: 40px;
          }
        }

        & > .body {
          display: flex;
          justify-content: center;
          font-size: 25px;
          color: rgb(222, 222, 222);

          & > span {
            padding-top: 40px;
            padding-left: 20px;
            padding-right: 20px;
            border-top: 2px solid rgb(222, 222, 222);
            font-weight: 400;
          }
        }
      }
    }
  }
}

section.second-view {
  & > article {
    display: flex;
    justify-content: center;
    padding: 0 54px;

    & > div.content {
      max-width: 950px;
      display: flex;
      flex-direction: column;
      text-align: center;

      & > span.headline {
        padding-top: 45px;
        padding-bottom: 65px;
        font-size: 30px;
        font-weight: 700;
        text-transform: uppercase;
      }

      & > span.body {
        padding-bottom: 45px;
        font-size: 20px;
      }

      @media (max-width: 700px) {
        text-align: unset;
      }
    }
  }
}

section.fifth-view {
  & > article {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #205574;
    height: 350px;
    padding: 0 54px;

    & > div.content {
      color: white;

      span.headline {
        font-size: 42px;
        font-weight: 700;
      }

      & > div.button-content {
        padding-top: 20px;
        display: flex;
        justify-content: center;

        & > button {
          padding: 0;
          border: 2px solid white;
          font-size: 18px;
          color: white;
          background-color: inherit;

          div {
            padding: 16px 24px;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}
